<script lang="ts" setup>
import { Article } from "types/Article";
import { QuillEditor, Quill, Delta } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const props = defineProps({
  article: {
    type: Object as PropType<Article>,
    required: true,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
});

const quillContent = new Delta(
  props.article.content ? JSON.parse(props.article.content) : undefined
);
</script>

<template>
  <div class="flex flex-col" id="article-viewer">
    <h2 class="text-h4 mb-4" v-if="hideTitle != true">
      {{ article.title }}
    </h2>
    <div
      :class="{
        border: hideTitle != true,
      }"
    >
      <QuillEditor
        :toolbar="[]"
        :content="quillContent"
        :read-only="true"
        :enable="false"
      />
    </div>
  </div>
</template>

<style>
#article-viewer .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: unset;
}

#article-viewer  .ql-toolbar {
  display: none;
}

#article-viewer  .ql-container.ql-snow {
  border: unset;
}

#article-viewer  .ql-container p {
  @apply text-body-1;
}
</style>
